<template>
    <div class="image-upload-box clearfix">
        <div class="image">
            <el-image 
                v-if="$utils.getFileType(urlFull) != 'pdf'"
                :src="urlFull" 
                fit="cover" 
                v-loading="uploadLoading" 
                :preview-src-list="[...urlFull]"
            >
                <div slot="error" class="image-slot">
                    <slot name="image-slot">
                        <i class="iconfont">&#xe639;</i>
                    </slot>
                </div>
            </el-image>
            <pdf v-else :src="urlFull"></pdf>
            <div v-if="urlFull" class="tool">
                <div>
                    <span class="img-preview el-icon-zoom-in" @click="preview"></span>
                    <el-button icon="el-icon-delete" type="text" @click="remove"></el-button>
                </div>
            </div>
        </div>
        <div class="image-upload-content">
            <ul>
                <li>
                    <i :class="formatVerified | verified"></i>
                    格式: <slot name="type">支持格式为JPG、PNG</slot>
                </li>
                <li>
                    <i :class="sizeVerified | verified"></i> 
                    尺寸:
                    <slot v-if="!width && !height && !minWidth && !minHeight" name="size">
                        无特殊要求
                    </slot>
                    <slot v-else name="size">
                        {{minWidth && minHeight ? '不小于'+minWidth+'*'+minHeight+'px' : ''}}
                        {{maxWidth && maxHeight ? '不大于'+maxWidth+'*'+maxHeight+'px' : ''}}
                        {{width && height ? '必须为'+width+'*'+height+'px' : ''}}
                    </slot>
                </li>
                <li>
                    <i :class="bytesVerified | verified"></i>
                    大小: 大小不超过10M
                </li>
				
            </ul>
            <div class="image-upload-btn" >
                <!-- <el-button v-if="isClear" icon="el-icon-delete" @click="remove">删除</el-button> -->
                <el-upload
                    action
                    :accept="accept"
                    :http-request="uploadFile"
                    :show-file-list="false"
                    :disabled="uploadLoading"
                    :before-upload="beforeImageUpload"
                >
                    <el-button type="primary" icon="el-icon-upload" size="small"
                        >{{url ? '重新上传' : '点击上传'}}</el-button
                    >
                </el-upload>
            </div>
        </div>

        <el-dialog
            title="裁剪图片"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            :width="parseInt(cropperBoxWidth + 40)+'px'"
            append-to-body
        >
            <div class="cropper-content" :style="{height:cropperBoxHeight+'px'}">
                <vue-cropper
                    ref="cropper"
                    :img="copperOptions.img"
                    :fixed="true"
                    :auto-crop="true"
                    output-type="png"
                    :fixed-number="copperOptions.fixedNumber"
                    :centerBox="false"
                    :infoTrue="true"
                    :fixedBox="true"
                    :auto-crop-width="cropperBoxWidth"
                    :auto-crop-height="cropperBoxHeight"
                    :enlarge="copperOptions.enlarge"
                ></vue-cropper>
            </div>
            <div slot="footer" class="dialog-footer clearfix">
                <div class="tool fl">
                    <el-button icon="el-icon-zoom-in" @click="changeScale(1)"></el-button>
                    <el-button icon="el-icon-zoom-out" @click="changeScale(-1)"></el-button>
                    <el-button icon="el-icon-refresh-left" @click="rotateLeft"></el-button>
                    <el-button icon="el-icon-refresh-right" @click="rotateRight"></el-button>
                </div>
                <div class="btn fr">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="finish" :loading="uploadLoading">确定</el-button>
                </div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="previewVisible" title="高清预览" center append-to-body width="90%">
            <div class="preview-box">
                <template v-if="urlFull && $utils.getFileType(urlFull) == 'pdf'">
                    <pdf ref="pdf" v-for="i in numPages" :key="i" :src="pdfUrl" :page="i"></pdf>
                </template>
                <img v-else :src="urlFull">
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {uploadImg,uploadImgByBase64,uploadFile} from '@/assets/js/commonApi.js';
import { VueCropper }  from 'vue-cropper' 
import pdf from 'vue-pdf'
import CMapReaderFactory from 'vue-pdf/src/CMapReaderFactory.js'

export default {
    filters: {
        verified(v) {
            if (v === null) {
                return ["el-icon-warning-outline"];
            } else if (v === false) {
                return ["el-icon-circle-close", "error"];
            } else {
                return ["el-icon-circle-check", "success"];
            }
        },
    },
    props: {
        // 图片相对路径
        url: {
            type: String,
            default: () => "",
        },
        // 图片全路径
        urlFull: {
            type: String,
            default: () => "",
        },
        // 上传图片最小宽度
        minWidth:{
            type: Number,
            default: null
        },
        // 上传图片最小高度
        minHeight:{
            type: Number,
            default: null
        },
        // 上传图片最大宽度
        maxWidth:{
            type: Number,
            default: null
        },
        // 上传图片最大高度
        maxHeight:{
            type: Number,
            default: null
        },
        // 上传图片要求宽度
        width:{
            type: Number,
            default: null
        },
        // 上传图片要求高度
        height:{
            type: Number,
            default: null
        },
        // 是否可删除
        // isClear:{
        //     type: Boolean,
        //     default: false
        // },
        // 是否需要裁剪
        crop:{
            type: Boolean,
            default: false
        },
        // 原图路径
        original:{
            type: String,
            default: () => null,
        },
        //上传成功回调
        // onSuccess:{
        //     type: Function,
        //     default: () => {}
        // },
        accept:{
            type: String,
            default: 'image/png, image/jpeg',
        }
    },
    components:{VueCropper,pdf},
    data() {
        return {
            uploadLoading: false,
            formatVerified: null,
            sizeVerified: null,
            bytesVerified: null,
            progress: null,
            dialogVisible: false,
            copperOptions:{
                img:'',
                fixedNumber:[this.width || this.minWidth || 1, this.height || this.minHeight || 1],
                cropWidth: this.width || this.minWidth,
                cropHeight: this.height || this.minHeight,
                enlarge:''
            },
            previewVisible:false,
            imageObj:null,
            numPages: null, // pdf 总页数
            pdfUrl: '',
            cropperBoxWidth:600,
            cropperBoxHeight: 400
        };
    },
    mounted(){
        if(this.urlFull && this.$utils.getFileType(this.urlFull) == 'pdf'){
            this.getNumPages();
        }
        if( this.copperOptions.cropWidth > this.copperOptions.cropHeight ){
            this.copperOptions.enlarge = this.copperOptions.cropWidth > this.cropperBoxWidth ? this.copperOptions.cropWidth/this.cropperBoxWidth : 1
        }else{
            this.copperOptions.enlarge = this.copperOptions.cropHeight > this.cropperBoxHeight ?  this.copperOptions.cropHeight / this.cropperBoxHeight : 1
        }
    },
    methods: {
        async beforeImageUpload(file){
            
            //格式检查
            let _accept = this.accept.split(',') || [];
            _accept.map( (item,index) => {
                _accept[index] = item.trim();
            })
            this.formatVerified = _accept.find( v => v == file.type);
            if (!this.formatVerified) {
                this.$message.error("文件格式错误,请重新上传");
                //return;
            }
            //大小检查
            this.bytesVerified = file.size / 1024 / 1024 < 10;
            if (!this.bytesVerified) {
                this.$message.error("文件大小错误,请重新上传");
                //return;
            }
            if(this.formatVerified == 'image/png' || this.formatVerified == 'image/jpeg'){
                const { width, height } = await new Promise((resolve) => {
                    const _URL = window.URL || window.webkitURL;
                    const img = new Image();
                    img.onload = () => {
                        const { naturalWidth, naturalHeight } = img;
                        resolve({ width: naturalWidth, height: naturalHeight });
                    };
                    img.src = _URL.createObjectURL(file);
                    this.copperOptions.img = _URL.createObjectURL(file);
                });
                
                const _minW = this.minWidth ? width >= this.minWidth : true;
                const _minH = this.minHeight ? height >= this.minHeight : true;
                const _maxW = this.maxWidth ? width <= this.maxWidth : true;
                const _maxH = this.maxHeight ? height <= this.maxHeight : true;
                const _w = this.width ? width == this.width : true;
                const _h = this.height ? height == this.height : true;

                this.sizeVerified = !this.crop ? _minW && _minH && _maxW && _maxH && _w && _h : true;

                if (!this.sizeVerified ) {
                    this.$message.error("文件尺寸错误,请重新上传");
                    //return;
                }
            }else{
                this.sizeVerified = true;
            }

            if(this.formatVerified && this.bytesVerified && this.sizeVerified && this.crop){
                this.dialogVisible = true;
                this.imageObj = file;
            }

            return this.formatVerified && this.bytesVerified && this.sizeVerified;
        },

        async uploadFile(req,type) {
            if( this.formatVerified && this.bytesVerified && this.sizeVerified && (type && this.crop || !type && !this.crop) ){
                let res;
                this.uploadLoading = true;
                const formData = new FormData();
                
                if( type == 1 ){
                    formData.append("file", req);
                    res = await uploadImgByBase64(formData,ev => {
                        this.progress = Math.floor((ev.loaded / ev.total) * 100);
                    }).catch(() => {
                        this.$message.error( '上传失败');
                    }).finally(() => {
                        this.uploadLoading = false;
                    });

                }else{
                    const {
                        file
                    } = req;
                    formData.append("file", file || req);
                    res = this.formatVerified == 'image/png' || this.formatVerified == 'image/jpeg' ? 
                        await uploadImg(formData,ev => {
                            this.progress = Math.floor((ev.loaded / ev.total) * 100);
                        }).catch(() => {
                            this.$message.error( '上传失败');
                        }).finally(() => {
                            this.uploadLoading = false;
                        }) 
                        : await uploadFile(formData,ev => {
                            this.progress = Math.floor((ev.loaded / ev.total) * 100);
                        }).catch(() => {
                            this.$message.error( '上传失败');
                        }).finally(() => {
                            this.uploadLoading = false;
                        });
                }

                const {state,result,msg} = res.data;
                if(state == 1){
                    if( type != 3 ){
                        this.$emit(
                            "update:url",
                            result.url
                        );
                        this.$emit(
                            "update:urlFull",
                            result.urlFull
                        ); 
                    }else{ //裁剪前的原图
                        this.$emit(
                            "update:original",
                            result.url
                        ); 
                    }

                    this.$emit('onSuccess',req,result)

                    if(this.$utils.getFileType(result.urlFull) == 'pdf'){
                        this.getNumPages(result.urlFull);
                    }
                }else{
                    this.$message.error( msg || '上传失败');
                }
            }
            
        },
        //清除
        remove(){
            this.$emit(
                "update:url",""
            );
            this.$emit(
                "update:urlFull",""
            );
            this.formatVerified = this.sizeVerified = this.bytesVerified =  null;
        },
        //预览
        preview(){
            if(this.urlFull && this.$utils.getFileType(this.urlFull) == 'pdf'){
                window.open(this.urlFull);
            }else{
                this.previewVisible = true
            }
        },
        //完成裁剪
        finish(){

            if(this.original != null){ //上传原图
                this.uploadFile(this.imageObj, 3);
            }
            this.$refs.cropper.getCropData( async data => {
                await this.uploadFile(data, 1);
                this.dialogVisible = false;
            });
        },
        // handleImageSuccess(res,file,fileList){
        //     console.log(res)
        //     this.$emit('onSuccess',res,file,fileList)
        //     //this.onSuccess(res,file,fileList);
        // },
        //裁剪图片放大、缩小
        changeScale(num){
            num = num || 1 
            this.$refs .cropper.changeScale(num)
        },
        //裁剪图片左旋转
        rotateLeft(){
            this.$refs.cropper.rotateLeft();
        },
        //裁剪图片右旋转
        rotateRight(){
            this.$refs.cropper.rotateRight();
        },
        // 计算pdf页码总数
        getNumPages(_url) {
            const url = _url || this.urlFull;
            this.pdfUrl = pdf.createLoadingTask({ url: url, CMapReaderFactory });
            let loadingTask = pdf.createLoadingTask(url)
            loadingTask.promise.then(pdf => {
                this.numPages = pdf.numPages
            }).catch(err => {
                console.error('pdf 加载失败', err);
            })
        },
    },
};
</script>
<style lang="scss" scoped>
.image-upload-box {
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
    .image {
        width: 200px;
        height: 200px;
        margin-right: 8px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        background: #FCFCFC;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        .el-image{
            max-width: 100%;
            max-height: 100%;
        }
        .tool{
            position: absolute;
            background: rgba(0,0,0,0.5);
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            align-items: center;
            justify-content: center;
            .el-button,.img-preview{
                color: #ffffff;
                font-size: 18px;
                font-weight: 700;
                margin: 0 5px;
            }
            .img-preview{
                cursor: pointer;
            }
            
        }
        &:hover{
            .tool{
                display: flex;
            }
        }
    }
    .image-upload-content {
        flex: 1;
        background: #FCFCFC;
        border-radius: 8px;
        overflow: hidden;
        padding: 20px;
        position: relative;
        height: 200px;
        min-width: 180px;
        ul {
            li {
                line-height: normal;
                color: $--color-info;
                margin-bottom: 5px;
                font-size:13px;
                i {
                    &.error {
                        color: #f56c6c;
                    }

                    &.success {
                        color: #67c23a;
                    }
                }
            }
        }
        .image-upload-btn{
            position: absolute;
            left: 20px;
            bottom: 20px;
            width: 100%;
            // margin-top: 50px;
            // &.flex{
            //     display: flex;
            // }
            // .el-button{
            //     margin-right: 10px;
            // }
            ::v-deep .el-upload {
                width: 180px;
                .el-button {
                    width: 100%;
                    margin: 0;
                }
            }
            // .only{
            //     ::v-deep .el-upload{
            //         width: 100%;
            //     }   
            // }
        }
    }
}

// @media screen and (max-width: 768px) {
//     .image-upload-box{
//         .image-upload-content{
//             ul{
//                 margin-top: 10px;
//             }
//             .image-upload-btn{
//                 margin-top: 10px;
//             }
//         }
//     }
// }


.cropper-content {
    height: 400px;
    width: auto;
}

.preview-box{
    text-align: center;
    img{
        max-width: 100%;
    }
}
</style>